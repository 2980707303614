import { RadioGroup, RadioProvider } from '@ariakit/react'
import { useSignals } from '@preact/signals-react/runtime'
import Icon from '@susu/headless-commerce/components/Icon/Icon'
import styles from '@susu/headless-commerce/components/LanguageSelector/LanguageSelector.module.scss'
import { translateName } from '@susu/headless-commerce/components/LanguageSelector/utils/translateName'
import { Radio } from '@susu/headless-commerce/components/Radio/ClientRadio'
import { baseURL } from '@susu/headless-commerce/config/config'
import type {
  AllowedLocaleCountry,
  AllowedLocaleLanguage,
} from '@susu/headless-commerce/config/locale'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { ChangeLanguageProperties } from '@susu/headless-commerce/libs/avo/avo'
import { setLastVisitedLocaleCookie } from '@susu/headless-commerce/utils/cookies/browser'
import {
  convertLocaleToCookieFormat,
  createLocale,
  getCountryFromLocale,
  getLanguageFromLocale,
} from '@susu/headless-commerce/utils/localeUtils'
import log from '@susu/headless-commerce/utils/log'
import { pathnameWithoutLocalePrefix } from '@susu/headless-commerce/utils/pathname'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import { trackEvent } from '@susu/headless-commerce/utils/tracking/tracking'
import classNames from 'classnames'
import { usePathname } from 'next/navigation'
import type { SyntheticEvent } from 'react'
import { useCallback } from 'react'

export type ClientLanguageSelectorProps = {
  onClose: () => void
  isInsideFooter: boolean
}

export default function ClientLanguageSelector({
  onClose,
  isInsideFooter,
}: ClientLanguageSelectorProps) {
  useSignals()

  const pageType = usePageType()
  const country = useCountry()
  const locale = useLocale()
  const pathname = usePathname()

  const localeLanguage = getLanguageFromLocale(locale)
  const localeCountry = getCountryFromLocale(locale)

  const handleLanguageChange = useCallback(
    async (toLanguage: string) => {
      const toLocale = createLocale(
        toLanguage as AllowedLocaleLanguage,
        localeCountry as AllowedLocaleCountry,
      )

      const eventAction = 'Change_Language'
      const eventCategory = isInsideFooter
        ? 'Footer_Interactions'
        : 'Global_Interactions'
      const eventLabel = `${localeLanguage}_${toLanguage}`.toLowerCase()

      const { url, locationId } = URLData()

      await trackEvent({
        ga: {
          eventCategory,
          eventAction,
          eventLabel,
        },
        segment: {
          event: 'changeLanguage',
          enrichProps: {
            locale,
            country,
          },
          properties: {
            pageType: pageType as ChangeLanguageProperties['pageType'],
            eventLabel: eventLabel,
            eventCategory: isInsideFooter
              ? 'footer_interactions'
              : 'global_interactions',
            eventLocation: isInsideFooter ? 'footer' : 'menu',
            url,
            locationId,
          },
        },
      })

      const currentUrl = new URL(baseURL())
      currentUrl.pathname = `/${toLocale}${pathnameWithoutLocalePrefix(pathname)}`

      await setLastVisitedLocaleCookie(convertLocaleToCookieFormat(toLocale))

      document.location.href = currentUrl.toString()
    },
    [
      country,
      isInsideFooter,
      locale,
      localeCountry,
      localeLanguage,
      pageType,
      pathname,
    ],
  )

  const onLanguageChange = useCallback(
    async (e: SyntheticEvent<HTMLInputElement, Event>) => {
      const language = (e.target as HTMLInputElement).dataset.language
      if (!language) {
        log.error(new Error('Language not found'))
        return
      }

      await handleLanguageChange(language)
    },
    [handleLanguageChange],
  )

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const languageListClasses = classNames(
    styles['language-selector__languages'],
    'language-selector__items',
  )

  return (
    <div className={styles['language-selector']}>
      <div className={styles['language-selector__back-button']}>
        <button
          type="button"
          onClick={handleClose}
          data-testid="button_language-selector_back"
        >
          <Icon icon="arrow_left" />
        </button>
      </div>

      <div
        className={languageListClasses}
        data-testid="language-selector_items"
      >
        <RadioProvider defaultValue={translateName(localeLanguage)}>
          <RadioGroup>
            {country.languages.map((language: string) => {
              return (
                <Radio
                  key={language}
                  id={`lang-${language}`}
                  value={translateName(language)}
                  data-language={language}
                  data-testid={`language-selector_item_${language}`}
                  onChange={onLanguageChange}
                  className={styles['language-selector__radio']}
                  tabbable
                />
              )
            })}
          </RadioGroup>
        </RadioProvider>
      </div>
    </div>
  )
}
