'use client'

import { useSignal, useSignalEffect } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { loaded as analyticsLoaded } from '@susu/headless-commerce/contexts/analytics'
import {
  cart,
  cartSSMProducts,
  cartSegmentProducts,
} from '@susu/headless-commerce/contexts/cart'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import {
  customer,
  customerPromise,
} from '@susu/headless-commerce/contexts/customer'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { PageTypeValueType } from '@susu/headless-commerce/libs/avo/avo'
import { inspectorPromise } from '@susu/headless-commerce/libs/segment/avoDestination'
import { gaPromise } from '@susu/headless-commerce/libs/segment/utils'
import { CID_STORAGE_KEY } from '@susu/headless-commerce/libs/sfcc/constants'
import { getCookie } from '@susu/headless-commerce/utils/cookies/browser'
import { isServer } from '@susu/headless-commerce/utils/environment'
import log from '@susu/headless-commerce/utils/log'
import { pushGA } from '@susu/headless-commerce/utils/tracking/GA'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import {
  insertCookieTrackingScript,
  pageLoaded,
} from '@susu/headless-commerce/utils/tracking/tracking'
import { parallel } from '@susu/promise'
import { isUndefined } from '@susu/undefined'

const promise = parallel([
  () => customerPromise,
  () => inspectorPromise,
  () => gaPromise,
])

export default function ClientTrackingOnLoad() {
  useSignals()

  const country = useCountry()
  const locale = useLocale()
  const pageType = usePageType()
  const countryCode = country.countryCode
  const currencyCode = country.ecommerce.currencyCode
  const uid = getCookie(CID_STORAGE_KEY)

  const initialized = useSignal(false)
  useSignalEffect(() => {
    log.trace({
      method: 'ClientTrackingOnLoad:effect',
      country,
      locale,
      pageType,
      countryCode,
      currencyCode,
      uid,
      initialized: initialized.value,
      cart: cart.value,
      cartSSMProducts: cartSSMProducts.value,
      cartSegmentProducts: cartSegmentProducts.value,
      analyticsLoaded: analyticsLoaded.value,
    })

    if (isServer() || initialized.value) {
      return
    }

    // insertGoogleTrackingScript()
    insertCookieTrackingScript()

    if (
      analyticsLoaded.value === false ||
      isUndefined(cart.value) ||
      isUndefined(cartSSMProducts.value) ||
      isUndefined(cartSegmentProducts.value)
    ) {
      return
    }
    ;(async () => {
      await promise

      log.trace({
        method: 'ClientTrackingOnLoad:effect:initializing',
        country,
        locale,
        pageType,
        countryCode,
        currencyCode,
        uid,
        initialized: initialized.value,
        analyticsLoaded: analyticsLoaded.value,
        cart: cart.value,
        cartSSMProducts: cartSSMProducts.value,
        cartSegmentProducts: cartSegmentProducts.value,
      })

      pushGA({
        country: countryCode,
        currency: currencyCode,
        ecomm_category: '',
        ecomm_pagetype: pageType,
        ecomm_section: 'Men',
        ecommerce: {
          currencyCode: country.ecommerce.currencyCode,
        },
        language: country.languages[0],
        login_state: customer.value?.isRegistered ?? false,
        page_language: `${country.languages[0]}_${country.countryCode.toUpperCase()}`,
        sfcc_id: uid as string,
        ssm_products: cartSSMProducts.value,
        uid: uid ? String(uid) : undefined,
      })

      const { url, locationId } = URLData()

      await pageLoaded({
        enrichProps: {
          country,
          locale,
        },
        properties: {
          customDestinationPageName_: pageType,
          locationId,
          pageType: pageType as PageTypeValueType,
          productsCart: cartSegmentProducts.value || [],
          url,
        },
      })
    })()

    initialized.value = true
  })

  log.trace({
    method: 'ClientTrackingOnLoad',
    country,
    locale,
    pageType,
    countryCode,
    currencyCode,
    uid,
    initialized: initialized.value,
    analyticsLoaded: analyticsLoaded.value,
    cart: cart.value,
    cartSSMProducts: cartSSMProducts.value,
    cartSegmentProducts: cartSegmentProducts.value,
  })

  return <></>
}
