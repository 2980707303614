import { signal } from '@preact/signals-react'
import type { Locale } from '@susu/headless-commerce/config/locale'
import { customer } from '@susu/headless-commerce/contexts/customer'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import { getCookie } from '@susu/headless-commerce/utils/cookies/browser'
import { isBrowser } from '@susu/headless-commerce/utils/environment'
import { errorHandler } from '@susu/headless-commerce/utils/errorHandler'
import {
  convertLocaleToSegmentFormat,
  getCountryFromLocale,
  getLanguageFromLocale,
} from '@susu/headless-commerce/utils/localeUtils'
import log from '@susu/headless-commerce/utils/log'
import { createPromiseFromSignal } from '@susu/headless-commerce/utils/signal'
import type { CommonEventProperties } from '@susu/headless-commerce/utils/tracking/segment'

import { parallel } from '@susu/promise'
import type {
  CountryValueType,
  CurrencyValueType,
  LanguageValueType,
  LoggedInValueType,
} from '../avo/avo'
import {
  GA_COOKIE_ID,
  OPTANON_COOKIE_NAME,
  OPTANON_DEFAULT_CONSENT,
} from './constants'

export const transformOneTrustConsent = (
  consent: string,
): {
  categoryPreferences: Record<string, boolean>
} => {
  // Check if gtag is defined and has a valid definition
  const categories = consent
    .split(',')
    .reduce((acc: Record<string, boolean>, category: string) => {
      const [key, value] = category.split(':')
      acc[key] = value === '1'
      return acc
    }, {})

  return {
    categoryPreferences: categories,
  }
}

export const getOneTrustConsent = (): string => {
  let consentOnetrust = OPTANON_DEFAULT_CONSENT
  let cookieOptanonConsent = getCookie(OPTANON_COOKIE_NAME)

  if (cookieOptanonConsent) {
    cookieOptanonConsent = decodeURIComponent(cookieOptanonConsent)

    if (cookieOptanonConsent.indexOf('&groups=') !== -1) {
      consentOnetrust = cookieOptanonConsent.split('&groups=')[1].split('&')[0]
    }
  }

  return consentOnetrust
}

export const gaLoaded = signal<boolean | undefined>(undefined)
export const gaPromise = createPromiseFromSignal(gaLoaded)

gaPromise.then((value) => {
  log.debug({
    method: 'libs/segment/utils:customerPromise:then',
    value,
  })
})

export const createGAdata = async (): Promise<unknown> => {
  try {
    const [clientId, sessionId, sessionNumber] = await parallel([
      () => getGtagData('client_id'),
      () => getGtagData('session_id'),
      () => getGtagData('session_number'),
    ])

    window.localStorage.setItem('client_id', clientId as string)
    window.localStorage.setItem('session_id', sessionId as string)
    window.localStorage.setItem('session_number', sessionNumber as string)

    console.log(`session ID: ${sessionId}`)
    console.log(`session Number: ${sessionNumber}`)
    console.log(`client ID: ${clientId}`)

    // Rreturning this since is type unknown
    // just to satisfy the typechecking
    gaLoaded.value = true

    return clientId
  } catch (error) {
    errorHandler(error as Error)
  }
}

export const getGtagData = (key: string) => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    if (typeof gtag === 'function' && GA_COOKIE_ID) {
      // @ts-ignore
      gtag('get', GA_COOKIE_ID, key, resolve)
      return
    }

    reject()
  })
}

export type EnrichEventsProps = {
  locale: Locale
  country: CountryConfiguration
}

export const enrichEvent = <T>(
  { locale, country }: EnrichEventsProps,
  baseProperties: T,
): T & CommonEventProperties => {
  const loggedIn: LoggedInValueType = customer.value?.isRegistered
    ? 'true'
    : 'false'
  const props = {
    ...baseProperties,
    country: getCountryFromLocale(locale).toLowerCase() as CountryValueType,
    currency: country.ecommerce.currencyCode.toLowerCase() as CurrencyValueType,
    language: getLanguageFromLocale(locale) as LanguageValueType,
    localeVisited: convertLocaleToSegmentFormat(locale),
    userId: customer.value?.userId ?? '',
    loggedIn,
    fbFbp: '',
    fbFbc: '',
    gaAwClid: '',
    gaClientId: '',
    gaSessionId: '',
    gaSessionNumber: '',
  }

  if (isBrowser()) {
    const { fbFbc, fbFbp, gaAwClid } =
      getGoogleAndFacebookAttributesFromCookies()

    props.fbFbc = fbFbc
    props.fbFbp = fbFbp
    props.gaAwClid = gaAwClid
    props.gaClientId = window?.localStorage?.getItem('client_id') || ''
    props.gaSessionId = window?.localStorage?.getItem('session_id') || ''
    props.gaSessionNumber =
      window?.localStorage?.getItem('session_number') || ''

    log.trace({
      method: 'enrichEvent',
      props,
    })
  }

  return props
}

/**
 * Get Google and Facebook attributes from cookies
 * @returns {fbFbc: string, fbFbp: string, gaAwClid: string}
 */
export const getGoogleAndFacebookAttributesFromCookies = (): {
  fbFbc: string
  fbFbp: string
  gaAwClid: string
} => {
  const fbFbc = getCookie('_fbc') || ''
  const fbFbp = getCookie('_fbp') || ''
  const gaAwClid = getCookie('_gcl_aw')?.split('.')[2] || ''

  return { fbFbc, fbFbp, gaAwClid }
}
