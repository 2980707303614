import type { JSDOM as JSDOMType } from 'jsdom'
import { isBrowser } from './environment'

let JSDOM: typeof JSDOMType

/**
 * Decodes HTML entities in a string
 * @param label - string to decode
 * @returns decoded string
 */
export const decodeHTML = (label: string): string => {
  // isServer() is not working.
  if (typeof window === 'undefined') {
    JSDOM = require('jsdom').JSDOM
    const dom = new JSDOM()
    const tempElement = dom.window.document.createElement('div')
    tempElement.innerHTML = label

    return tempElement.textContent || ''
  }

  if (isBrowser()) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(label, 'text/html')

    return doc.body.textContent || ''
  }

  return label
}
